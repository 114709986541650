/* eslint-disable array-callback-return */
/* eslint-disable no-tabs */
/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react'
import '../assets/css/common.css'
import './ofertas-do-dia.scss'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Swiper from 'react-id-swiper'
import setaesquerda from '../assets/images/ofertasdodia/setaesquerda.svg'
import setadireita from '../assets/images/ofertasdodia/setadireita.svg'
import { Helmet } from 'react-helmet'
import LojaProximaOferta from '../components/SectionOfertasDia/lojaproxima'
import WhatsappOferta from '../components/SectionOfertasDia/whatsapp'

export default function OfertasDoDia ({ data }) {
  const [validaWhatsapp, setValidaWhatsapp] = useState(false)
  const [numero, setNumero] = useState(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const current = JSON.parse(window.localStorage.getItem('currentStore'))
      if (current && current.whatsapp) {
        setNumero(current.whatsapp)
        setValidaWhatsapp(true)
      }
    }
  }, [])

  const ofertas = []
  const dias = [
    { id: 1, tag: 'segunda' },
    { id: 2, tag: 'terca' },
    { id: 3, tag: 'quarta' },
    { id: 4, tag: 'quinta' },
    { id: 5, tag: 'sexta' },
    { id: 6, tag: 'sabado' },
    { id: 7, tag: 'domingo' }
  ]

  const diaHoje = new Date().getDay()

  const hoje = dias.filter((dia) => dia.id === diaHoje)[0]
  data.Ofertas.nodes.map((tabloide) => {
    tabloide.offer.map((off) => {
      if (off.tag) {
        if (off.tag.indexOf(hoje.tag) !== -1) {
          ofertas.push(off)
        }
      }
    })
  })

  const swiperParams = {
    slidesPerView: 2,
    spaceBetween: 5,
    breakpoints: {
      1000: {
        slidesPerView: 4,
        spaceBetween: 24
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 24
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  const [swiper, updateSwiper] = useState(null)
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt" />
        <title>OFERTAS DO DIA | Drogaleste</title>
        <meta name="title" content='OFERTAS DO DIA | Drogaleste' />
        <meta name="description" content='OFERTAS DO DIA | Drogaleste' />
        <meta name="keywords" content='ofertas-do-dia,dia,ofertas,drogaleste' />
        <meta name="author" content="Drogaleste" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content='OFERTAS DO DIA | Drogaleste' />
        <meta property="og:type" content="article" />
        <meta property="og:url" content='https://www.drogaleste.com.br'/>
        <meta property="og:image" content='https://www.drogaleste.dev.clouddog.com.br/Card_Share_Drogaleste.png' />
        <meta property="og:description" content='OFERTAS DO DIA | Drogaleste' />
      </Helmet>
      <div className="bg-ofertasdodia position-relative">
        <div className="container-fluid">
          <div className="row no-gutters">
            <div className="position-bolinhas">
              <GatsbyImage
                image={data.bolinhas.childImageSharp.gatsbyImageData}
                alt='bolinhas'
              />
            </div>
            <div className="position-ondas col-lg-8 d-lg-block d-none">
              <GatsbyImage
                image={data.ondas.childImageSharp.gatsbyImageData}
                alt='ondas'
                className=''
              />
            </div>
          </div>
        </div>
        <div className="container mt-1">
          <div className="row">
            <div className="col-lg-5">
              <h2 className="text-blue text-top text-lg-left text-center">Todo dia um mundo de<br /> <span className="text-red"> ofertas para você!</span></h2>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-1 d-flex justify-content-center">
              <button onClick={goPrev} className="bottom-prev border-0 bg-transparent">
                <img src={setaesquerda} />
              </button>
            </div>
            <div className="col-10 p-lg-0">
              <div className="card-carousel">
                <Swiper {...swiperParams} getSwiper={updateSwiper}>
                  {ofertas.map((off) => {
                    return (
                      <div key={off.id}><GatsbyImage image={off.image.childImageSharp.gatsbyImageData} alt="icone" className="w-100 rounded" /></div>
                    )
                  })}
                </Swiper>
              </div>
            </div>
            <div className="col-1 d-flex justify-content-center">
              <button onClick={goNext} className="bottom-next border-0 bg-transparent">
                <img src={setadireita} />
              </button>
            </div>
            <div className="col-lg-3 col-7 text-center mt-4">
              <GatsbyImage
                image={data.selo.childImageSharp.gatsbyImageData}
                alt='selo'
                className=''
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="w-100 text-center pb-1">
            <h2 className="text-ofertas" style={{ color: '#20438c' }}>Confira as categorias participantes<br /> da campanha e não perca as <b className="text-ofertas-bold">ofertas do dia.</b></h2>
          </div>
          <div className="row no-gutters seven-cols">
            <div className="col-lg-1 col-6">
              <Link to="/segundasenior/">
                <GatsbyImage
                  image={data.card1.childImageSharp.gatsbyImageData}
                  alt='card1'
                />
              </Link>
            </div>
            <div className="col-lg-1 col-6">
              <Link to="/tercafit/">
                <GatsbyImage
                  image={data.card2.childImageSharp.gatsbyImageData}
                  alt='card2'
                  className=''
                />
              </Link>
            </div>
            <div className="col-lg-1 col-6">
              <Link to="/quartamedicamentos/">
                <GatsbyImage
                  image={data.card3.childImageSharp.gatsbyImageData}
                  alt='card3'
                  className=''
                />
              </Link>
            </div>
            <div className="col-lg-1 col-6">
              <Link to="/quintadafarmacinha/">
                <GatsbyImage
                  image={data.card4.childImageSharp.gatsbyImageData}
                  alt='card4'
                  className=''
                />
              </Link>
            </div>
            <div className="col-lg-1 col-6">
              <Link to="/sextadabeleza/">
                <GatsbyImage
                  image={data.card5.childImageSharp.gatsbyImageData}
                  alt='card5'
                  className=''
                />
              </Link>
            </div>
            <div className="col-lg-1 col-6">
              <Link to="/sabadodocuidadopessoal/">
                <GatsbyImage
                  image={data.card6.childImageSharp.gatsbyImageData}
                  alt='card6'
                  className=''
                />
              </Link>
            </div>
            <div className="col-lg-1 d-lg-block d-none">
              <Link to="/domingokids/">
                <GatsbyImage
                  image={data.card7.childImageSharp.gatsbyImageData}
                  alt='card7'
                  className=''
                />
              </Link>
            </div>
            <div className="col-12 d-lg-none d-block">
              <Link to="/domingokids/">
                <GatsbyImage
                  image={data.card7mob.childImageSharp.gatsbyImageData}
                  alt='card7'
                  className=''
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-blue-ofertasdia py-3">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p><b className="text-white">Ofertas válidas na compra de até 5 peças de cada produto<br /> por cliente, até o término dos nossos estoques.</b></p>
              <p className="text-white mb-0">Encontre uma loja perto de você: <Link to="/lojas/" className="text-white"><b>https://www.drogaleste.com.br/lojas/</b></Link><br />Fale conosco: <Link to="/contato/" className="text-white"><b>https://www.drogaleste.com.br/contato/</b></Link></p>
            </div>
          </div>
        </div>
      </div>
      <div className="position-fixed fixed-bottom">
        <div className='row align-items-center justify-content-between px-5'>
          {console.log(validaWhatsapp, numero)}
          {validaWhatsapp && <WhatsappOferta numero={numero} /> }
          <LojaProximaOferta />
        </div>
      </div>
    </Fragment>
  )
}

export const query = graphql`
  query {
    Ofertas: allCloudOfertasTabloide(
      filter: {tariff: {name: {eq: "OFERTAS-DO-DIA"}}}
    ) {
      nodes {
        offer {
          category {
            name
            id
          }
          startDate
          finishDate
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
          ownBrand
          specialOffers
          order
          format
          id
          tag
        }
        legalText
        finishDate
        startDate
        status
      }
    }
    segundasenior: file(relativePath: {eq: "images/ofertasdodia/segunda.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 1140, height: 900 )
      }
    }
    tercafit: file(relativePath: {eq: "images/ofertasdodia/terca.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 1140, height: 900 )
      }
    }
    quartamedicamentos: file(relativePath: {eq: "images/ofertasdodia/quarta.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 1140, height: 900 )
      }
    }
    quintadafarmacinha: file(relativePath: {eq: "images/ofertasdodia/quinta.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 1140, height: 900 )
      }
    }
    sextadabeleza: file(relativePath: {eq: "images/ofertasdodia/sexta.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 1140, height: 900 )
      }
    }
    sabadodocuidadopessoal: file(relativePath: {eq: "images/ofertasdodia/sabado.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 1140, height: 900 )
      }
    }
    domingokids: file(relativePath: {eq: "images/ofertasdodia/domingo.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 1140, height: 900 )
      }
    }
    card1: file(relativePath: {eq: "images/ofertasdodia/card-01.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card2: file(relativePath: {eq: "images/ofertasdodia/card-02.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card3: file(relativePath: {eq: "images/ofertasdodia/card-03.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card4: file(relativePath: {eq: "images/ofertasdodia/card-04.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card5: file(relativePath: {eq: "images/ofertasdodia/card-05.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card6: file(relativePath: {eq: "images/ofertasdodia/card-06.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card7: file(relativePath: {eq: "images/ofertasdodia/card-07.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card7mob: file(relativePath: {eq: "images/ofertasdodia/card-domingo-mob.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    drogaleste: file(relativePath: {eq: "images/ofertasdodia/drogaleste.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 162, height: 34 )
      }
    }
    selo: file(relativePath: {eq: "images/ofertasdodia/selo.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 378, height: 408 )
      }
    }
    bolinhas: file(relativePath: {eq: "images/ofertasdodia/bolinhas-maior.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    ondas: file(relativePath: {eq: "images/ofertasdodia/ondas.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 1920, height: 396 )
      }
    }
  }
`
