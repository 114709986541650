/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'
import iconLocalization from '../../assets/icons/all/icon-map.svg'
import './SectionOfertasDia.scss'

export default function LojaProximaOferta () {
  return (
    <Link to="/lojas/" className="bg-LojaProxima-OfertasDia d-flex text-white">
      <img src={iconLocalization} alt="icone de localização" width="20" height="20" className="mr-1" />ver loja próxima
    </Link>
  )
}
