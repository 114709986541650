/* eslint-disable react/prop-types */
import React from 'react'
// import { Link } from 'gatsby'
import Whatsapp from '../../assets/images/ofertasdodia/whatsapp.svg'
import './SectionOfertasDia.scss'

export default function WhatsappOferta ({ numero }) {
  const regex = /[^0-9]+/g
  return (
    <a href={`https://api.whatsapp.com/send?=pt_BR&phone=55${numero.replace(regex, '')}`} className="bg-Whatsapp-OfertasDia d-flex text-white align-items-center cursor-pointer px-lg-auto px-3" target="_blank" rel="noreferrer noopener">
      <img src={Whatsapp} alt="icone de localização" width="30" height="30" className="mr-lg-1" /><span className="d-lg-block d-none">Solicite agora</span>
    </a>
  )
}
